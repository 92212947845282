<template>
  <LayoutDataTable
    :isLoading="showTable"
    :searchQuery="searchQuery"
    :perPage="perPage"
    :meta="dataMeta"
    :currentPage="currentPage"
    :totals="totals"
    :searchPlaceholder="$t('lbl_search_name__brand_or_sku')"
    :colsLeft="6"
    :colsRight="6"
    @setPerPage="(value) => (perPage = value)"
    @setCurrentPage="(value) => (currentPage = value)"
    @setSearchQuery="(value) => (searchQuery = value, currentPage = 1)"
  >
    <!-- <template #cta>
      <div class="ml-1 w-50">
        <v-select
          class="bg-white"
          v-model="selectedFilter"
          label="name"
          value="code"
          append-to-body
          taggable
          :id="`select-filter`"
          :clearable="false"
          :options="filterOptions"
          @input="handleSelectedFilter"
        >
          <template slot="no-options">{{ $t('lbl_no_option') }}</template>
        </v-select>
      </div>
    </template>-->
    <template #default>
      <b-table
        ref="refProductsTable"
        class="position-relative"
        :items="fetchProductsList"
        :filter="{
          type: productType,
        }"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        hover
        :empty-text="$t('lbl_no_matching_records_found')"
        :sort-desc.sync="isSortDirDesc"
        @row-clicked="handleViewRecord"
      >
        <template #cell(sku)="scope">
          <span class="d-inline-block">
            {{
            scope.item.product.sku || appUtils.randomCode(10)
            }}
          </span>
        </template>
        <template #cell(primary_image)="scope">
          <img
            class="border rounded-lg"
            :src="scope.item.product.primary_image"
            :alt="scope.item.product.name"
            :height="48"
            @error="(e) => appUtils.onHandleErrorLoadImage(e)"
          />
        </template>
        <template #cell(name)="scope">
          <span class="d-inline-block text-truncate" style="max-width: 150px">
            {{
            scope.item.product.name
            }}
          </span>
        </template>
        <template #cell(category)="scope">
          <!-- <b-badge
            :style="
              index > 0
                ? {
                    marginLeft: `8px`,
                  }
                : {}
            "
            v-for="(cate, index) in scope.item.category"
            :key="cate.id"
            >{{ cate.name }}</b-badge
          >-->
          <b-badge>{{ scope.item.category.name }}</b-badge>
        </template>
        <template #cell(type)="scope">
          <b-badge :variant="getProductType(scope.item.product.type).variant">
            {{
            $t(Object.keys(PRODUCT_TYPES)[scope.item.product.type - 1])
            }}
          </b-badge>
        </template>
        <template #cell(default_price)="scope">
          <span class="text-nowrap">
            {{
            scope.item.product.default_price === 0
            ? "Liên hệ"
            : appUtils.numberFormat(scope.item.product.default_price)
            }}
          </span>
        </template>
        <template #cell(quantity)="scope">
          <span
            v-if="scope.item.product.type === PRODUCT_TYPES.PRODUCT"
            class="text-nowrap"
          >{{ getQuantityProduct(scope.item) }}</span>
          <span v-else class="text-nowrap">{{ $t("lbl_unlimited") }}</span>
        </template>
        <template #cell(available)="scope">
          <div class="text-right text-nowrap" @click.stop>
            <div>
              <b-form-checkbox
                :checked="scope.item.product.is_available === 1"
                class="custom-control-success"
                name="check-button"
                switch
                @change="handleChangeStatus(scope.item)"
              />
            </div>
          </div>
        </template>
      </b-table>
    </template>
  </LayoutDataTable>
</template>

<script>
import appUtils from '@/utils/appUtils'
import { PRODUCT_ON_INVENTORY_STATUS, PRODUCT_TYPES } from '@/utils/constant'
import { createPopper } from '@popperjs/core'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import LayoutDataTable from '../../layouts/table/LayoutDataTable.vue'
import useProductInventoryList from './useProductInventoryList'

export default {
  name: 'ProductsList',
  components: {
    LayoutDataTable,
    vSelect
  },
  data() {
    return {
      appUtils,
      PRODUCT_TYPES,
      placement: 'top',
      selectedFilter: {
        code: 'SELECTED_FILTER_ALL',
        name: this.$t('lbl_all')
      },
      filterOptions: [
        {
          code: 'SELECTED_FILTER_ALL',
          name: this.$t('lbl_all')
        },
        {
          code: appUtils.randomCode(10),
          name: this.$t('PRODUCT')
        },
        {
          code: appUtils.randomCode(10),
          name: this.$t('SERVICE')
        }
      ]
    }
  },
  computed: {
    ...mapGetters('app', ['statusOptions']),
    productType() {
      return this.$route?.meta?.type
    }
  },
  methods: {
    getQuantityProduct(row) {
      let total = 0
      if (row.product_variant.length > 0) {
        row.product_variant.forEach(x => {
          total += x.qty
        })
        return appUtils.numberFormat(total)
      } else {
        return total === 0 ? this.$t('lbl_sold_out') : ''
      }
    },
    refreshTable() {
      this.showTable = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.refetchData()
        this.showTable = false
      }, 500)
    },
    formatDate(time) {
      return moment(time).format('HH:mm:ss DD/MM/YYYY')
    },
    deleteRecord(record) {
      if (!record?.id) {
        return
      }

      const nodeEl = this.$createElement
      const messageVNode = nodeEl('div', {
        domProps: {
          innerHTML: `Are you sure want to delete record #<strong>${record.id}</strong>?`
        }
      })

      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            // Code here...
            console.log(value)
          }
        })
        .catch(err => {
          // An error occurred
          console.log(err)
        })
    },
    getImage(imageUrl) {
      const img = appUtils.checkingImageUrl(imageUrl)
      console.log(img)
      return img
    },
    handleViewRecord(record) {
      const routeType =
        record.product.type === PRODUCT_TYPES.PRODUCT ? 'products' : 'services'
      this.$router.push({
        path: `/${routeType}/${record.product.slug}-${record.product_id}`
      })
    },
    handleChangeStatus(data) {
      if (!data.id) return

      const params = {
        ...data,
        is_available:
          data.product?.is_available === PRODUCT_ON_INVENTORY_STATUS.AVAILABLE
            ? PRODUCT_ON_INVENTORY_STATUS.UNAVAILABLE
            : PRODUCT_ON_INVENTORY_STATUS.AVAILABLE
      }
      this.updateProductStatus(params)
    },
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1]
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              )
            }
          }
        ]
      })

      return () => popper.destroy()
    },
    handleSelectedFilter(value) {
      console.log(value)
    }
  },
  setup() {
    const dataTag = ref({})
    const mixData = useProductInventoryList()
    return { ...mixData, dataTag }
  }
}
</script>

<style scoped lang="scss">
.b-table-details {
  background-color: #f6f6f9;
}
</style>
